import React from 'react';
import icon from "../../assets/LogoMawinguf.png";
import "../../styles/layout.css";
import { Link } from "react-router-dom";

function Header() {

return (
<div className="top-nav">
<Link to="/">
      <img className="Logo" alt="Logo" src={icon} />
    </Link>
        <div className="button-container" >
          <button className="auth-button" >
                  Swahili
                </button>
                <button className="auth-button" 
                >
                  Somali
                </button>
          </div>

      </div>
);
}

export default Header;